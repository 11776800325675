<template>
    <div class="phone_page">
        <div class="page_container">
            <div class="page_title">
                <div class="page_title_en">好物疆至旅游产业园</div>
                <div class="page_title_cn">（夏塔古道园）</div>
            </div>
            <div class="container_title">
                首家“野奢酒店+互动体验产销区”沉浸式体验园
            </div>
            <div class="page_text">
                <div class="point"></div>
                <div class="text_container">
                    好物疆至旅游产业园一期南靠天山山脉，北接夏特克尔克孜民族乡。项目计划用地330000平方米，总投资7亿，分两期开发，一期投资3.3亿，计划2023年8月营业。
                </div>
            </div>
            <div class="page_text2">
                <div class="point"></div>
                <div class="text_container">
                    园区内主要由高端客房、文化长廊、冰雪乐园及可视化产销区组成。用地由左至右分别为酒店综合楼A区、酒店综合楼B区（框架结构、地上3层地下1层）、连接A、B区的观景文化廊道（2049.49平方米）、可视化互动体验产销区，其中客房总计217间（A区客房67间、B区客房139间、星空帐篷房11间），可同时接纳约450人入住。
                </div>
            </div>
            <div class="box1">
                <div class="img_container1">
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/3business/1.jpg" />
                </div>
            </div>
            <div class="page_text3">
                <div class="point"></div>
                <div class="text_container">
                    建筑风格主要以柯尔克孜族传统民居“勃孜吾依”为原型，反复运用圆形和弧形，建筑色彩以纯白色为主，窗户为大面积落地玻璃，建筑布局及角度经反复考虑，最大化的利用了临天山景观资源。达到环境与建筑风格相协调目的，形成独具特色的建筑群落。
                </div>
            </div>
            <div class="page_text2">
                <div class="point"></div>
                <div class="text_container">
                    旅产园一期建成后将成为昭苏首屈一指的符合当代消费者度假需求的野奢型酒店，同时通过可视化互动体验产销区，将昭苏农副产品进行整合及品牌化升级，全面提升产品的附加值，并以好物疆至零售终端体系为核心渠道，将昭苏的产品销售到全国市场。 
                </div>
            </div>
            <div class="img_container2">
                <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/3business/2.jpg" />
            </div>
        </div>
        <footer1></footer1>
    </div>
</template>

<script>
export default {
    mounted() {
        this.animate.moveIn(".phone_title1", ".phone_title1", "bottom");
    }
}
</script>

<style lang="scss" scoped>
.phone_page{
    display: block;
    position:relative;
    margin-top: 140px;

    img{
        height: 344px;
    }
    .phone_title1{
        position: absolute;
        left: 88px;
        right: 0;
        top: 108px;
        width: 180px;
        height: 150px;
        color: white;
        font-size: 25px;
        p{
            font-size: 30px;
            font-weight: 600;
            color: white;
        }
        span{
            font-size: 20px;
            line-height: 60px;
        }
    }

    .container_title{
        margin-left: 54px;
        margin-bottom: 32px;
        font-size: 28px;
        margin-top: 30px
    }

    .page_text2,.page_text,.page_text3{
        display: flex;
            align-items: center;
            margin-bottom: 56px;
            padding: 0 32px;
            line-height: 35px;
            .point{
                width:12px;
                height: 12px;
                border-radius: 50%;
                background-color: #8bc243;
                margin-right: 22px;
            }

            .text_container{
                flex: 1;
                height: 30px;
            }
    }

    .page_text{
        margin-bottom: 190px;
    }

    .page_text2{
        margin-bottom: 340px;
    }

    .page_text3{
        margin-bottom: 250px;
        margin-top: 100px;
    }

    .box1{
        display: flex;
        justify-content: end;
        .img_container1{
            width: 526px;
            height: 406px;
            background-color: rgba(245,245,245,1);
            position: relative;

            img{
                top:-44px;
                position: absolute;
                left:-104px
            }
        }
    }

    .img_container2{
        width: 470px;
        height: 506px;
        background-color: rgba(245,245,245,1);
        position: relative;

        img{
            width: 532px;
            position: absolute;
            height: 462px;
            top:88px;
            left:96px;
        }
    }

    .page_container{
        width: 100%;
        padding: 0 32px;
        margin-top: 58px;
        box-sizing: border-box;

        .page_title{
            .page_title_en{
                font-size: 36px;
                line-height: 40px;
                color:#8bc243;
            }

            .page_title_cn{
                font-size: 32px;
                line-height: 64px;
                width: auto;
                display: inline-block;
                color:#8bc243;
                border-bottom: 2px solid #8bc243;
                margin-bottom: 30px;
            }
        }
        .page_text{
            font-size: 24px;
            line-height: 44px;
            color:#333;
        }

        .page_img{
            width: 686px;
            height: 470px;
            margin-top:134px;
            background-color: #f2f2f2;
            position: relative;

            img{
                width: 592px;
                height: 450px;
                position: absolute;
                left:48px;
                bottom: 94px;
            }
        }
    }
}
</style>