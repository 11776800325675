<template>
    <div>
        <div id="page1" class="page1-wrap">
            <div class="about_container_title">
                <div class="about_container_title_en">好物疆至旅游产业园</div>
                <div class="about_container_title_cn">（夏塔古道园）</div>
                <div class="round_A">
                    <div class="round"></div>
                    <div class="text_1">
                        <div class="about_container_title_son">首家“野奢酒店+互动体验产销区”沉浸式体验园</div>
                        <div class="about_container_text">好物疆至旅游产业园一期南靠天山山脉，北接夏特克尔克孜民族乡。项目计划用地330000平方米，总投资7亿，分两期开发，一期投资3.3亿，计划2023年8月营业。
                        </div>
                    </div>
                </div>
                <div class="round_B">
                    <div class="round"></div>
                    <div class="text_2">园区内主要由高端客房、文化长廊、冰雪乐园及可视化产销区组成。用地由左至右分别为酒店综合楼A区、酒店综合楼B区（框架结构、地上3层地下1层）、连接A、B区的观景文化廊道（2049.49平方米）、可视化互动体验产销区，其中客房总计217间（A区客房67间、B区客房139间、星空帐篷房11间），可同时接纳约450人入住。</div>
                </div>
            </div>
            <div class="about_container_img">
                <img class="about_container_img_1" style="background: var(--img-bg)" src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/3business/1.jpg" width="100%" alt="">
            </div>
        </div>
        <div class="company_one">
            <div class="company_one_title_one">
                <div class="company_one_title_one_content">
                    <div class="round"></div>
                    <div class="company_one_title_one_content_2">建筑风格主要以柯尔克孜族传统民居“勃孜吾依”为原型，反复运用圆形和弧形，建筑色彩以纯白色为主，窗户为大面积落地玻璃，建筑布局及角度经反复考虑，最大化的利用了临天山景观资源。达到环境与建筑风格相协调目的，形成独具特色的建筑群落。</div>
                </div>
                <div class="company_one_title_one_content_A">
                    <div class="round"></div>
                    <div class="company_one_title_one_content_2">旅产园一期建成后将成为昭苏首屈一指的符合当代消费者度假需求的野奢型酒店，同时通过可视化互动体验产销区，将昭苏农副产品进行整合及品牌化升级，全面提升产品的附加值，并以好物疆至零售终端体系为核心渠道，将昭苏的产品销售到全国市场。</div>
                </div>
            </div>
            <div class="company_one_img">
                <img class="company_one_img_1" style="background: var(--img-bg)" src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/3business/2.jpg" width="100%" alt="">
            </div>
        </div>

        <div class="page_5">
            <div class="page3_title">
                <div class="page3_title_cn">
                    <div class="page_title_c">业态赏析
                    </div>
                </div>
            </div>
            <div id="homePage2" class="page2-wrap">
                <div class="page5-img">
                    <div class="img4-1" @mouseover="changeText(0)" @mouseout="show = false">
                        <div class="content">
                            <div>天马体验</div>
                            <p>TIANMA EXPERIENCE</p>
                        </div>
                        <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/4.png">
                    </div>
                    <div class="img4-2" @mouseover="changeText(1)" @mouseout="show = false">
                        <div class="content">
                            <div>文化长廊</div>
                            <p>CULTURAL  CORRIDOR</p>
                        </div>
                        <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/5.png">
                    </div>
                    <div class="img4-3" @mouseover="changeText(2)" @mouseout="show = false">
                        <div class="content">
                            <div>冰雪乐园</div>
                            <p>ICE AND SNOW PARK</p>
                        </div>
                        <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/6.png">
                    </div>
                    <div class="img4-4" @mouseover="changeText(3)" @mouseout="show = false">
                        <div class="content">
                            <div>可视化互动产销区</div>
                            <p>PRODUCTION-SALES AREA</p>
                        </div>
                        <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/7.jpg">
                    </div>
                </div>
            </div>
            <div class="page5_content" :class="show ? 'active' : ''">
                <div class="page5_content_one">
                    <div class="round"></div>
                    <div>{{ dataList[index].title }}</div>
                    <div class="ti">{{ dataList[index].en_title }}</div>
                </div>
                <p>{{dataList[index].text}}</p>
            </div>
        </div>
        <footer1></footer1>
    </div>
</template>

<script>
export default {
    data() {
        return {
            index:0,
            show:false,
            dataList:[
                {
                    title:'天马体验',
                    en_title:'TIANMA EXPERIENCE',
                    text:'昭苏是天马的故乡，已举办30届享誉国内外的“天马国际旅游节”，好物疆至将在旅产园内建立“天马体验”业态，分为两大体验内容：一是成人马术体验，让来昭苏的游客充分感受在草原上策马奔腾的豪迈，体验昭苏天马的神俊；二是宠物马体验，让家庭式游客中的儿童也可尽享骑乘的乐趣，同时成为旅产园对外宣传的一个重要爆点。'
                },
                {
                    title:'文化长廊',
                    en_title:'CULTURAL  CORRIDOR',
                    text:'昭苏拥有丰富的在地文化，好物疆至将通过建立百米观景文化长廊的形式，对昭苏的美食、音乐、艺术、绘画、非遗等各类文化进行整合，让游客在昭苏的璀璨星空和巍峨雪山下，品着马奶酒，听着异域音乐……全身心地放松，全身心地感悟昭苏的魅力。'
                },
                {
                    title:'冰雪乐园',
                    en_title:'ICE AND SNOW PARK',
                    text:'昭苏拥有丰富的冰雪旅游资源，好物疆至响应国家、自治区、自治州等各级政府关于发展冰雪旅游的规划，建立“冰雪乐园”业态，包含雪圈冲浪、冰雕、雪地摩托、冰上碰碰车马拉雪橇等游玩项目，为昭苏的冬季旅游增加新的内容。'
                },
                {
                    title:'可视化互动产销区',
                    en_title:'PRODUCTION-SALES AREA',
                    text:'好物疆至以可视化互动产销区为载体，向游客集中式展示昭苏产品加工环节的健康，同时充分利用南北疆核心交通节点的区位优势，通过“酒店现场购买”、“好物疆至连锁零售终端”、“全疆其他合作售卖渠道”等，将昭苏的好肉、好奶、好产品售卖到全国。'
                }
            ]
        }
    },
    methods:
        {
            changeText(index){
                this.index = index
                this.show = true
            } },
    mounted() {
        this.animate.moveIn(".page1_about", ".page1_about", "bottom");
    }
}
</script>

<style lang="scss" scoped>
.page1-wrap {
    position: relative;
    margin-top: 70px;
    height: 900px;
    .about_container_title{
        width: 650px;
        height: 610px;
        position: absolute;
        left:297px;
        top:120px;

        .about_container_title_en{
            font-size: 46px;
            line-height: 52px;
            color:#8BC243;
            margin-bottom: 14px;
        }
        .about_container_title_cn{
            display: inline-block;
            font-size: 30px;
            height: 56px;
            color:#8BC243;
            margin-bottom: 40px;
            border-bottom: 2px solid #8BC243;
        }
        .round_A,.round_B{
          display: flex;
          margin-top: 50px;
          .round{
            width: 15px;
            height: 15px;
            opacity: 1;
            background: rgba(139, 194, 67, 1);
            border-radius: 50px;
            position: absolute;
            margin-top: 8px;
          }
          .text_1{
            margin-left: 50px;
            .about_container_title_son{
              font-size: 26px;
              margin-bottom: 20px;
            }
            .about_container_text{
              font-size: 17px;
              line-height: 30px;
              color: rgba(54, 54, 54, 1);
              margin-top: 50px;
              width: 550px;
            }

          }
          .text_2{
            font-size: 17px;
            line-height: 30px;
            color: rgba(54, 54, 54, 1);
            margin-left: 50px;
            width: 550px;
          }
        }
    }
    .about_container_img{
        width: 531px;
        height: 354px;
        position: absolute;
        top:420px;
        right:300px;
        background-color: #f6f6f6;
        .about_container_img_1{
            width: 466px;
            height: 359px;
            position: relative;
            top: -80px;
            left:-55px;
        }
    }
}

.company_one{
  display: flex;
  position: relative;
  margin-bottom: 400px;
  padding: 0 434px 0 300px;
  flex-direction: row-reverse;
  justify-content: space-between;
  .company_one_title_one{
    margin-left: 50px;
    .company_one_title_one_content,.company_one_title_one_content_A{
      display: flex;
      margin-top: 120px;
      .round{
        left: 300px;
        top: 2385px;
        width: 15px;
        height: 15px;
        opacity: 1;
        background: rgba(139, 194, 67, 1);
        margin-right: 20px;
        border-radius: 50px;
        margin-top: 7px;
      }
      .company_one_title_one_content_2{
        width: 510px;
        font-size: 17px;
        color: rgba(54, 54, 54, 1);
        line-height: 30px;

      }
    }
  }
  .company_one_img{
    width: 420px;
    height: 454px;
    background-color: #f6f6f6;
    margin-right: 200px;
    .company_one_img_1{
      width: 480px;
      height: 451px;
      position: relative;
      top: 78px;
      left:78px;
    }
  }
}

.page3_title{
  left: 705px;
  top: 2185.32px;
  opacity: 1;
  font-size: 46px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 64.4px;
  color: rgba(139, 194, 67, 1);
  text-align: left;
  vertical-align: top;
  margin-bottom: 50px;
  .page3_title_cn{
    display: flex;
    margin-left: 300px;
    .page_title_c{
      display: inline-block;
      color:#8bc243;
      font-size: 30px;
      line-height: 58px;
      border-bottom: 2px solid #8bc243;
    }
  }
}
.page_5{
  margin-bottom: 400px;
  .page2-wrap {
    top: 2360px;
    width: 1920px;
    height: 747px;
    opacity: 1;
    background: rgba(247, 247, 247, 1);
    .page5-img{
      top: 10px;
      width: 1920px;
      height: 747px;
      display: flex;
      padding: 0 20rem;
      box-sizing: border-box;
      opacity: 1;
      background: rgb(247, 247, 247);
      justify-content: space-around;
      flex-direction: row;
      .img4-1,.img4-2,.img4-3,.img4-4{
        top: 94px;
        position: relative;
        width: 253px;
        height: 411px;
        overflow: hidden;
        //opacity: 1;
        background: rgb(180, 180, 180,0.1);
        .content {
          position: absolute;
          top: 0%;
          opacity: 1;
          left: 0;
          width: 253px;
          height: 100%;
          text-align: center;
          transition: all .6s;
          background-color: rgba(0,0,0,.5);
          -webkit-animation: 0.75s 0.75s forwards slide_in;
          animation: 0.75s 0.75s forwards slide_in;
          display: flex;
          color:white;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          div{
            font-size: 18px;
            margin-bottom: 6px;
          }
          p{
            opacity: .5;
          }
        }
        img{
          width: 253px;
          height: 411px;
        }

      }
      .img4-1:hover>.content{
        opacity: 0;
      }
      .img4-2{
        top: 171px;
      }
      .img4-3{
        top: 94px;
      }
      .img4-4{
        top: 171px;
      }
      .img4-2:hover>.content{
        opacity: 0;
      }
      .img4-3:hover>.content{
        opacity: 0;
      }
      .img4-4:hover>.content {
        opacity: 0;
      }
    }
  }
}

.page5_content{
  left: 0px;
  top: 1065px;
  width: 1920px;
  transition: all .6s;
  overflow: hidden;
  height: 0px;
  opacity: 1;
  background: rgba(247, 247, 247, 1);
  .page5_content_one{
    color: #0e0b0b;
    font-size: 18px;
    margin-bottom: 6px;
    width:600px;
    margin-left: 305px;
    display: flex;
    align-items: center;
    .round{
      left: 342px;
      top: 1083px;
      width: 12px;
      height: 12px;
      opacity: 1;
      border-radius:50px;
      background: rgba(139, 194, 67, 1);
      margin-bottom: 6px;
    }
    div{
      margin-right: 18px;
    }
    .ti{
      font-size: 16px;
      opacity: 0.5;
    }
  }
  p{
    margin-top: 45px;
    margin-left: 330px;
    width:1335px;
    font-size: 17px;
    opacity: 0.7;
  }

}

.active{
  height: 260px;
}
</style>