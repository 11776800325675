var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"page_5"},[_vm._m(2),_c('div',{staticClass:"page2-wrap",attrs:{"id":"homePage2"}},[_c('div',{staticClass:"page5-img"},[_c('div',{staticClass:"img4-1",on:{"mouseover":function($event){return _vm.changeText(0)},"mouseout":function($event){_vm.show = false}}},[_vm._m(3),_c('img',{attrs:{"src":"https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/4.png"}})]),_c('div',{staticClass:"img4-2",on:{"mouseover":function($event){return _vm.changeText(1)},"mouseout":function($event){_vm.show = false}}},[_vm._m(4),_c('img',{attrs:{"src":"https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/5.png"}})]),_c('div',{staticClass:"img4-3",on:{"mouseover":function($event){return _vm.changeText(2)},"mouseout":function($event){_vm.show = false}}},[_vm._m(5),_c('img',{attrs:{"src":"https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/6.png"}})]),_c('div',{staticClass:"img4-4",on:{"mouseover":function($event){return _vm.changeText(3)},"mouseout":function($event){_vm.show = false}}},[_vm._m(6),_c('img',{attrs:{"src":"https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/7.jpg"}})])])]),_c('div',{staticClass:"page5_content",class:_vm.show ? 'active' : ''},[_c('div',{staticClass:"page5_content_one"},[_c('div',{staticClass:"round"}),_c('div',[_vm._v(_vm._s(_vm.dataList[_vm.index].title))]),_c('div',{staticClass:"ti"},[_vm._v(_vm._s(_vm.dataList[_vm.index].en_title))])]),_c('p',[_vm._v(_vm._s(_vm.dataList[_vm.index].text))])])]),_c('footer1')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page1-wrap",attrs:{"id":"page1"}},[_c('div',{staticClass:"about_container_title"},[_c('div',{staticClass:"about_container_title_en"},[_vm._v("好物疆至旅游产业园")]),_c('div',{staticClass:"about_container_title_cn"},[_vm._v("（夏塔古道园）")]),_c('div',{staticClass:"round_A"},[_c('div',{staticClass:"round"}),_c('div',{staticClass:"text_1"},[_c('div',{staticClass:"about_container_title_son"},[_vm._v("首家“野奢酒店+互动体验产销区”沉浸式体验园")]),_c('div',{staticClass:"about_container_text"},[_vm._v("好物疆至旅游产业园一期南靠天山山脉，北接夏特克尔克孜民族乡。项目计划用地330000平方米，总投资7亿，分两期开发，一期投资3.3亿，计划2023年8月营业。 ")])])]),_c('div',{staticClass:"round_B"},[_c('div',{staticClass:"round"}),_c('div',{staticClass:"text_2"},[_vm._v("园区内主要由高端客房、文化长廊、冰雪乐园及可视化产销区组成。用地由左至右分别为酒店综合楼A区、酒店综合楼B区（框架结构、地上3层地下1层）、连接A、B区的观景文化廊道（2049.49平方米）、可视化互动体验产销区，其中客房总计217间（A区客房67间、B区客房139间、星空帐篷房11间），可同时接纳约450人入住。")])])]),_c('div',{staticClass:"about_container_img"},[_c('img',{staticClass:"about_container_img_1",staticStyle:{"background":"var(--img-bg)"},attrs:{"src":"https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/3business/1.jpg","width":"100%","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company_one"},[_c('div',{staticClass:"company_one_title_one"},[_c('div',{staticClass:"company_one_title_one_content"},[_c('div',{staticClass:"round"}),_c('div',{staticClass:"company_one_title_one_content_2"},[_vm._v("建筑风格主要以柯尔克孜族传统民居“勃孜吾依”为原型，反复运用圆形和弧形，建筑色彩以纯白色为主，窗户为大面积落地玻璃，建筑布局及角度经反复考虑，最大化的利用了临天山景观资源。达到环境与建筑风格相协调目的，形成独具特色的建筑群落。")])]),_c('div',{staticClass:"company_one_title_one_content_A"},[_c('div',{staticClass:"round"}),_c('div',{staticClass:"company_one_title_one_content_2"},[_vm._v("旅产园一期建成后将成为昭苏首屈一指的符合当代消费者度假需求的野奢型酒店，同时通过可视化互动体验产销区，将昭苏农副产品进行整合及品牌化升级，全面提升产品的附加值，并以好物疆至零售终端体系为核心渠道，将昭苏的产品销售到全国市场。")])])]),_c('div',{staticClass:"company_one_img"},[_c('img',{staticClass:"company_one_img_1",staticStyle:{"background":"var(--img-bg)"},attrs:{"src":"https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/3business/2.jpg","width":"100%","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page3_title"},[_c('div',{staticClass:"page3_title_cn"},[_c('div',{staticClass:"page_title_c"},[_vm._v("业态赏析 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',[_vm._v("天马体验")]),_c('p',[_vm._v("TIANMA EXPERIENCE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',[_vm._v("文化长廊")]),_c('p',[_vm._v("CULTURAL CORRIDOR")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',[_vm._v("冰雪乐园")]),_c('p',[_vm._v("ICE AND SNOW PARK")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',[_vm._v("可视化互动产销区")]),_c('p',[_vm._v("PRODUCTION-SALES AREA")])])
}]

export { render, staticRenderFns }